import { Link, navigate } from 'gatsby';
import React from 'react';
import { Layout } from '../components';
import { TemplateProps } from '../parser';

const P404: React.FunctionComponent<TemplateProps<{
  rewrites: Array<{
    to: string;
    from: string[];
  }>;
}>> = ({ location, pageContext }) => {
  const rewrites = pageContext.rewrites;

  if (typeof window !== 'undefined') {
    const pathname = location.pathname;
    let found = false;
    for (let i = 0; i < rewrites.length; i++) {
      const rewrite = rewrites[i];
      for (let j = 0; j < rewrite.from.length; j++) {
        const from = rewrite.from[j];
        const fromBase = from.replace(/\*/g, '');
        if (pathname.startsWith(fromBase)) {
          found = true;
          if (rewrite.to.endsWith('*')) {
            const pathRest = pathname.replace(fromBase, '');
            const nextPath = rewrite.to.replace('*', pathRest);
            navigate(nextPath, {
              replace: true,
            });
          } else {
            navigate(rewrite.to, {
              replace: true,
            });
          }
          break;
        }
      }
      if (found) {
        break;
      }
    }
  }

  return (
    <Layout title="404 - Page Not Found">
      <div className="wrapper">
        <div className="notFound">
          <h1 className="notFound--title">404 - Page not found</h1>
          <p className="notFound--description">
            The requested URL was not found on this server. Please try another
            page.
          </p>
          <Link to="/" className="notFound--redirect">
            Back Home
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default P404;
